<template>
  <div>
    <b-row>
      <b-col class="my-2">
        <b-button  @click="showFilters = !showFilters"  variant="flat-secondary" class="float-right">
          <iconBase iconName="filter_asc" :width="18" :height="18" iconColor="#626262" class="align-middle mr-2"><iconFilter/></iconBase>
          <span v-show="!showFilters"  class="font-bold">{{$t('Labels.ShowFilters')}}</span>
          <span v-show="showFilters"  class="font-bold">{{$t('Labels.HideFilters')}}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-show="showFilters">
      <!-- <b-col sm="auto" cols="3">
        <b-form-group>
          <label class="font-bold" for="inputImei">{{$t('Labels.IMEI')}}</label>
          <b-form-input v-model="filters.imei" id="inputImei"/>
        </b-form-group>
      </b-col> -->
      <b-col sm="auto" cols="3">
        <b-form-group>
          <label class="font-bold" or="inputAlias">{{$t('Labels.Alias')}}</label>
          <b-form-input v-model="filters.name" id="inputAlias"/>
        </b-form-group>
      </b-col>
      <b-col sm="auto" cols="3" class="text-center container-btn-filters">
        <b-button @click="doFilter()" variant="primary" class="mr-2">{{$t('Labels.Filter')}}</b-button>
        <b-button @click="resetFilters()" variant="outline-danger">{{$t('Labels.Clean')}}</b-button>
      </b-col>
    </b-row>
    <b-row class="container-filters-default">
        <b-col sm="12" md="auto" class="text-center my-2">
          <label>{{$t('Labels.OrderBy')}}</label>
          <v-select @input="getResponse" class="align-middle inline-block" :options="columnOrders" v-model="filters.byColumn" :reduce="v => v.value"  :clearable="false"/>
        </b-col>
        <b-col sm="12" md="auto" class="text-center my-2">
          <b-button size="sm" @click="filterOrders('ASC')"  variant="flat-secondary" class="btn-order" :class="{'bg-primary': filters.byOrder == 'ASC'}">
            <iconBase iconName="filter_asc" :width="18" :height="18" iconColor="#626262" class="align-middle"><iconFilterAsc/></iconBase>
          </b-button>
          <b-button  size="sm" @click="filterOrders('DESC')" variant="flat-secondary" class="btn-order" :class="{'bg-primary': filters.byOrder != 'ASC'}">
            <iconBase iconName="filter_desc" :width="18" :height="18" iconColor="#626262" class="align-middle"><iconFilterDesc/></iconBase>
          </b-button>
        </b-col>
        <b-col sm="12" md="auto" class="text-center my-2">
          <label>{{$t('Labels.ByPage')}}</label>
          <v-select @input="getResponse" class="align-middle inline-block" :options="byPage" v-model="filters.limit" :reduce="v => v.value"  :clearable="false"/>
        </b-col>
        <b-col sm="12" md="auto" class="text-center my-2">
          <a v-if="urlDownload.length > 0" class="btn btn-order btn-flat-secondary btn-sm text-primary align-middle" :href="urlDownload" download="Tracker.csv">
            <feather-icon icon="DownloadCloudIcon" size="20" />
          </a>
        </b-col>
    </b-row>
    <div class="">
        <b-row class="grid-container">
            <b-col cols="12" md="3" :key="item.objectID" @click="goToDetail(item.uuid)" class="grid-item" v-for="item in response">
                <b-card class="card-item">
                  <gridPrincipalItem
                    img="pointtracker"
                    :name="item.name"
                    :description="$t('Labels.Alias')"
                  />
                  <hr>
                  <valueItem
                    :value="item.deveui"
                    :description="$t('Labels.DevEui')"
                    img="alias"
                  />
                  <valueItem
                    :value="item.lat"
                    :description="$t('Labels.Latitude')"
                    img="georeference"
                  />
                  <valueItem
                    :value="item.long"
                    :description="$t('Labels.Longitude')"
                    img="georeference"
                  />
                  <valueItem
                    :value="batteryCalculate(item.battery)"
                    :description="$t('Labels.Battery')"
                    img="battery"
                    unit="%"
                  />
                  <hr>
                  <alertTracker :status="item.tipo_alerta" />
                  <valueItem
                    :value="item.updated_at.split(' ')[0]"
                    :description="item.updated_at.split(' ')[1]"
                    img="fecha"
                  ></valueItem>
                </b-card>
            </b-col>
        </b-row>
      <b-pagination v-if="response.length > 0" :per-page="filters.limit" :total-rows="totalPages" v-model="currentx" align="right"/>
    </div>
    <div v-if="response.length == 0" class="noData">{{$t('Messages.NoData')}}</div>
  </div>
</template>
<script>
import request from '@/libs/request/index'
import vSelect from 'vue-select'
import gridPrincipalItem from '@core/spore-components/gridPrincipalItem/gridPrincipalItem.vue'
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'
import iconBase from '@core/spore-components/icons/iconBase'
import iconFilterAsc from '@core/spore-components/icons/iconFilterAsc'
import iconFilterDesc from '@core/spore-components/icons/iconFilterDesc'
import iconFilter from '@core/spore-components/icons/iconFilter'
import { batteryCalculate, getAlertMessage } from '@/libs/tools/helpers'
import {
  BButton,
  BRow,
  BCol,
  BPagination,
  BFormInput,
  BFormGroup,
  BCard
} from 'bootstrap-vue'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: [
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
        deveui: '0011223344556677',
        serial: '1111111',
        imei: '1111111',
        alias: 'tracker 1',
        status: '1',
        latlong: '20.6738686,-103.3704324',
        created_at: '2021-08-20 17:29:11',
        updated_at: '2021-08-23 11:08:06'
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd450df4',
        deveui: '7766554433221100',
        serial: '222222',
        imei: '222222',
        alias: 'tracker 2',
        status: '0',
        latlong: '20.6738686,-103.3704345',
        created_at: '2021-08-20 17:29:11',
        updated_at: '2021-08-23 11:08:06'
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
        deveui: '0011223344556677',
        serial: '1111111',
        imei: '1111111',
        alias: 'tracker 1',
        status: '1',
        latlong: '20.6738686,-103.3704324',
        created_at: '2021-08-20 17:29:11',
        updated_at: '2021-08-23 11:08:06'
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd450df4',
        deveui: '7766554433221100',
        serial: '222222',
        imei: '222222',
        alias: 'tracker 2',
        status: '0',
        latlong: '20.6738686,-103.3704345',
        created_at: '2021-08-20 17:29:11',
        updated_at: '2021-08-23 11:08:06'
      }
    ]
  },
  msg: 'Ok'
}

export default {
  components: {
    'v-select': vSelect,
    gridPrincipalItem,
    valueItem,
    alertTracker,
    iconBase,
    iconFilterAsc,
    iconFilterDesc,
    iconFilter,
    BButton,
    BRow,
    BCol,
    BPagination,
    BFormInput,
    BFormGroup,
    BCard
  },
  data () {
    return {
      response: [],
      currentx: 1,
      totalRows: 0,
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'updated_at',
        byOrder: 'ASC',
        imei: '',
        alias: ''
      },
      urlDownload: '',
      showFilters: false,
      columnOrders: [
        { label: this.$t('Labels.Alias'), value: 'alias' },
        { label: this.$t('Labels.Date'), value: 'updated_at' }
      ],
      byPage: [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 75, value: 75 },
        { label: 100, value: 100 }
      ],
      DATA,
      batteryCalculate
    }
  },

  methods: {
    async getResponse () {
      const params = {
        url: 'trackers_admin',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(response => {
        this.response = response.data.data.rows
        this.totalRows = response.data.data.count
        this.createCsv()
      }).catch(() => {
        this.response = []
        this.setPages(0)
      })
    },

    goToDetail (uuid) {
      this.$router.push(`/tracker/detail/${uuid}`)
    },

    createCsv () {
      const csv = []
      const headers = []
      headers.push(this.$t('Labels.Alias'))
      headers.push(this.$t('Labels.DevEui'))
      headers.push(this.$t('Labels.Latitude'))
      headers.push(this.$t('Labels.Longitude'))
      headers.push(this.$t('Labels.Battery'))
      headers.push(this.$t('Labels.Status'))
      headers.push(this.$t('Labels.Date'))
      csv.push(headers.join(','))
      this.response.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.deveui)
        row.push(item.lat)
        row.push(item.long)
        row.push(batteryCalculate(item.battery) + '%')
        row.push(getAlertMessage(item.tipo_alerta))
        row.push(item.updated_at)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)
    },
    resetFilters () {
      this.filters.name = ''
      this.filters.imei = ''
    },
    doFilter () {
      if (this.currentx !== 1) { this.currentx = 1 } else { this.getResponse() }
    },
    filterOrders (param) {
      this.filters.byOrder = param
      this.getResponse()
    }

  },

  created () {
    this.getResponse()
  },

  watch: {
    currentx (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Grid.scss";
</style>
